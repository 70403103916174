/* clears the 'X' on inputs of type "search" on Internet Explorer */
.clean-search-input::-ms-clear,
.clean-search-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' on inputs of type "search" on all other browsers */
.clean-search-input::-webkit-search-results-button,
.clean-search-input::-webkit-search-results-decoration,
.clean-search-input::-webkit-search-cancel-button,
.clean-search-input::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
