#mobile-menu-toggle ~ label.icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  padding: 0;
  right: 1.5rem;
  top: 20px;
}

#mobile-menu-toggle ~ label.icon:before,
#mobile-menu-toggle ~ label.icon span,
#mobile-menu-toggle ~ label.icon:after {
  content: '';
  display: block;
  width: 1.5rem;
  height: 2px;
  border-radius: 10px;
  position: relative;
  transform-origin: 0;
  transition: all 0.2s linear;
}

#mobile-menu-toggle ~ label.icon-dark:before,
#mobile-menu-toggle ~ label.icon-dark span,
#mobile-menu-toggle ~ label.icon-dark:after {
  background: white;
}

#mobile-menu-toggle ~ label.icon-light:before,
#mobile-menu-toggle ~ label.icon-light span,
#mobile-menu-toggle ~ label.icon-light:after {
  background: black;
}

#mobile-menu-toggle:checked ~ label.icon-light:before,
#mobile-menu-toggle:checked ~ label.icon-light span,
#mobile-menu-toggle:checked ~ label.icon-light:after {
  background: black;
}

#mobile-menu-toggle:checked ~ nav ul li {
  color: white;
}

#mobile-menu-toggle ~ label.icon:before {
  transform: rotate(0);
}

#mobile-menu-toggle ~ label.icon span {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

#mobile-menu-toggle ~ label.icon:after {
  transform: rotate(0);
}

#mobile-menu-toggle:checked ~ label.icon:before {
  transform: rotate(45deg);
  max-width: 22.7px;
}

#mobile-menu-toggle:checked ~ label.icon span {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}

#mobile-menu-toggle:checked ~ label.icon:after {
  transform: rotate(-45deg);
  max-width: 22.7px;
}
