@font-face {
  font-family: 'HCo Whitney SSm';
  src: url('https://images.angelstudios.com/raw/upload/v1670366375/angel-studios/fonts/woff2/WhitneySSm-Light_Web.woff2') format('woff2');
  src: url('https://images.angelstudios.com/raw/upload/v1670366374/angel-studios/fonts/woff/WhitneySSm-Light_Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HCo Whitney SSm';
  src: url('https://images.angelstudios.com/raw/upload/v1670366375/angel-studios/fonts/woff2/WhitneySSm-Book_Web.woff2') format('woff2');
  src: url('https://images.angelstudios.com/raw/upload/v1670366374/angel-studios/fonts/woff/WhitneySSm-Book_Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HCo Whitney SSm';
  src: url('https://images.angelstudios.com/raw/upload/v1670366375/angel-studios/fonts/woff2/WhitneySSm-Medium_Web.woff2') format('woff2');
  src: url('https://images.angelstudios.com/raw/upload/v1670366374/angel-studios/fonts/woff/WhitneySSm-Medium_Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HCo Whitney SSm';
  src: url('https://images.angelstudios.com/raw/upload/v1670366375/angel-studios/fonts/woff2/WhitneySSm-Semibold_Web.woff2') format('woff2');
  src: url('https://images.angelstudios.com/raw/upload/v1670366374/angel-studios/fonts/woff/WhitneySSm-Semibold_Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HCo Whitney SSm';
  src: url('https://images.angelstudios.com/raw/upload/v1670366375/angel-studios/fonts/woff2/WhitneySSm-Bold_Web.woff2') format('woff2');
  src: url('https://images.angelstudios.com/raw/upload/v1670366374/angel-studios/fonts/woff/WhitneySSm-Bold_Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HCo Whitney SSm';
  src: url('https://images.angelstudios.com/raw/upload/v1670366375/angel-studios/fonts/woff2/WhitneySSm-Black_Web.woff2') format('woff2');
  src: url('https://images.angelstudios.com/raw/upload/v1670366374/angel-studios/fonts/woff/WhitneySSm-Black_Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nomadic Dreams';
  src: url('https://images.angelstudios.com/raw/upload/v1741109433/angel-studios/fonts/woff2/Nomadic_Dreams-Regular.woff2') format('woff2');
  src: url('https://images.angelstudios.com/raw/upload/v1741109409/angel-studios/fonts/woff/Nomadic_Dreams-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}