.customSlider {
    width: 85%;
    margin: 0 auto;
}

.customSlider-track {
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: #ddd;
    z-index: 1;
    border-radius: 100vw;
}

.customSlider-track.customSlider-track-0 {
    background: #52AD89;
}

.customSlider-thumb:hover {
    box-shadow: 0 0 0 8px rgb(104, 117, 217, 0.2);
}

.customSlider-mark {
    cursor: pointer;
    top: 6px;
    width: 1.5px;
    height: 8px;
    background-color: #aaaaaa;
}

.customSlider-mark.customSlider-mark-before {
    background-color: rgb(104, 117, 217);
}

.customSlider-mark.customSlider-mark-active {
    display: none;
}
