.seekbar-marker {
  height: 200% !important;
  text-align: center;
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.seekbar-marker-red4 {
  background-color: red !important;
}
.seekbar-marker-red3 {
  background-color: #ff5e5e !important;
}
.seekbar-marker-red2 {
  background-color: #fd8a8a !important;
}
.seekbar-marker-red1 {
  background-color: #ffc8c8 !important;
}

.seekbar-marker-green4 {
  background-color: #00cc00 !important;
}
.seekbar-marker-green3 {
  background-color: #4cc94c !important;
}
.seekbar-marker-green2 {
  background-color: #91d391 !important;
}
.seekbar-marker-green1 {
  background-color: #bde1bd !important;
}

.ping {
  animation: ping 0.2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
