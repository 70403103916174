button,
[type='button'],
[type='reset'],
[type='submit'] {
  background-image: unset;
}

@layer components {
  .btn {
    @apply relative flex items-center justify-center justify-items-center border font-semibold duration-200;
  }

  .btn-standard {
    @apply rounded-lg py-2 px-6;
  }

  .btn-xs {
    @apply rounded-lg py-2 px-4 text-xs leading-4 gap-1;
  }

  .btn-sm {
    @apply rounded-[0.625rem] py-[0.688rem] px-5 text-sm leading-[1.125rem] gap-1;
  }

  .btn-md {
    @apply rounded-xl py-[0.875rem] px-6 text-base leading-5 gap-1.5;
  }

  .btn-lg {
    @apply rounded-[0.875rem] py-[1.063rem] px-7 text-lg leading-[1.375rem] gap-1.5;
  }
}

@layer base {
  *:focus-visible {
    @apply outline-none ring-2 ring-copper/80 ring-offset-2 ring-offset-white/10 duration-100;
  }

  a {
    border-radius: 0.25rem;
  }
}
